*{
    z-index: unset;
}
.agree {
    color: green;
}

.disagree {
    color: red;
}

.speaking {
    background-color: rgb(143, 230, 136);
    width: 80%;
    border-radius: 0.75em;
    margin: 0.25em;
    margin-left: 3em;
}

.notspeaking {
    background-color: white;
    width: 80%;
    border-radius: 0.75em;
    border: solid lightgray 1px;
    margin: 0.25em;
    margin-left: 3em;
}

.activeTopic {
    font-size: 1.3em;
    font-weight: bold;
    background-color: #EEE;
    border-radius: 0.5em;
    margin: 0.25em;
}

#topic:hover .topic-grid{
/* display: block; */

 /* border: 1px solid blue !important; */
}

.topic-grid {
    display: block;
    /* margin-top: 2em; */
}
.participant-container{
    /* border: 1px solid black; */
    /* height: 5em; */
    /* padding: 5px; */
    /* margin: 20px 0; */
}
.participant-container:hover #participant-op{
    /* border: 1px solid rgb(255, 0, 0); */
    position: relative;
    display: block;
    z-index: 3;


}

#topic-btn:hover #topic-popover{
    /* margin: 2em; */
    /* display: none; */
}
#participant{
    --height: 1em;
    --width: 10%;
}
#participant-op{
    /* border: 1px solid red; */
    display: none;
}

.topic-popover-container:hover  #topic-popover{
    /* position: absolute;
    display: block;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 80000; */
}

 #topic-popover {
    --width: 60%;
    /* display: none; */
   
}


