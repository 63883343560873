.listItem        {
    margin: auto;
    width: 85%;
    min-height: 3.5em;
    border-radius: 0.5em;
    border: solid 1px lightgray;
    margin-top: 0.5em;
    padding: 0.35em;
}

.hostContainer {
    width: 50%;
    float: left;
}

.hostItem {
    padding: 0.25em;
    margin: 0.5em;
    border-radius: 0.25em;
    border: solid 1px lightgray;
}

.organizationBlock {
    
    float: 'left'; 
    width: '33%'; 
    height: '15em'; 
    display: 'block'; 
    border: 'solid black 1px';
    padding: '0.5em'; 
    margin: '0.5em'; 
    border-radius: '1em';
}